<template>
  <div class="list-active-models">
    <span v-if="loading">Loading</span>
    <span v-else="">
      <ActiveModel v-bind:modelsOnline="modelsOnline" />
    </span>
  </div>
</template>

<script>
// @ is an alias to /src
import ActiveModel from '@/components/ActiveModel'
const axios = require('axios');

export default {
  name: "ListActiveModels",
  components: {
    ActiveModel
  },
  data () {
    return {
      feedUrl: "https://chaturbate.com/api/public/affiliates/onlinerooms/?wm="+process.env.VUE_APP_AFFILIATE+"&client_ip=request_ip&limit=150&gender=t",
      modelFeed: null,
      loading: true,
      errored: false,
    }
  },
  computed: {
    modelsOnline: function () {
      return this.modelFeed.filter(function (model) {
        return model.current_show === 'public'
      })
    }
  },
  mounted() {
    axios
        .get(this.feedUrl)
        .then(response => {
          this.modelFeed = response.data.results;
          //console.log( `Count: ${response.data.count}`)
        })
        .catch(error => {
          console.log(error)
          this.errored = true
        })
        .finally(() => {
          this.loading = false
        })
  }
}
</script>

<style scoped lang="scss">

</style>
