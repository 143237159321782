<template>
  <div class="active-model">
    <div class="card-columns">
      <div v-for="model in modelsOnline" :key="model.username" class="card">
        <router-link :to="{ name: 'chatRoom', params: { username: model.username }}">
          <img v-lazy="model.image_url_360x270"
               v-observe-visibility="visibilityChanged"
               :alt="model.display_name + ' chat room preview image'"
               class="card-img-top room_preview"
               width="360" height="270"
               :data-src="require('@/assets/images/loading.gif')"
          >
        </router-link>
        <div class="card-body">
          <h5 class="card-title" v-if="model.username">
            <router-link :to="{ name: 'chatRoom', params: { username: model.username }}">{{ model.display_name }}</router-link>
             <span v-if="model.age">({{ model.age }})</span></h5>
          <p class="card-text">{{ model.room_subject }}</p>
        </div>
        <div class="card-footer">
          <small class="text-muted">Followers: {{ model.num_followers }} | Now watching: {{ model.num_users }}</small>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActiveModel",
  data() {
    return {
      isVisible: false
    }
  },
  props: {
    modelsOnline: {
      type: Array
    }
  },
  methods: {
    // https://stackoverflow.com/a/9943419/4058131
    reloadImage: function (event) {
      // console.log(`The button was clicked at ${event.target.src}.`);
      let chunks = event.target.src.split('#');
      return event.target.src = chunks[0] + '#' + new Date().getTime();
    },
    refreshThumbnails: function () {
      let thumbnails = document.getElementsByClassName('room_preview is-visible');
      let time = new Date().getTime();
      thumbnails.forEach(
          thumbnail => {
            let chunks = thumbnail.src.split('#');
            return thumbnail.src = chunks[0] + '#' + time;
          }
      )
    },
    visibilityChanged (isVisible, entry) {
      this.isVisible = isVisible
      if (isVisible) {
        entry.target.classList.add('is-visible')
        //entry.target.interval = setInterval(() => this.reloadImage(entry), 2000);
        this.reloadImage(entry)
      } else {
        entry.target.classList.remove('is-visible')
        //entry.target.interval = clearInterval()
      }
      // console.log(entry)
    },
    // imageReloadStart (entry) {
    //   entry.target.interval = setInterval(() => this.reloadImage(entry), 2000);
    // },
    // imageReloadStop (entry) {
    //   entry.target.interval = clearInterval()
    // }
  },
  created() {
    this.interval = setInterval(() => this.refreshThumbnails(), 4000);
  },
}
</script>

<style scoped lang="scss">
.room_preview {
  width: 100%;
  height: auto;
}
.card-columns {
  @include media-breakpoint-only(md) {
    column-count: 3;
  }
  @include media-breakpoint-only(lg) {
    column-count: 4;
  }
  @include media-breakpoint-only(xl) {
    column-count: 6;
  }
}
</style>
