<template>
  <div>
    <a href="/"><img src="@/../public/img/hot-tranny-tube.svg" alt=""></a>
    <h1 class="site-logo">Hot Tranny Tube</h1>
    <h3 class="text-center">The best online tranny cam models from around the world</h3>
    <ListActiveModels />
  </div>
</template>

<script>
// @ is an alias to /src
import ListActiveModels from '@/components/ListActiveModels'

export default {
  name: 'home',
  metaInfo: {
    title: 'Hot Tranny Tube',
    // https://alligator.io/vuejs/vue-seo-tips/,
    // override the parent template and just use the above title only
    titleTemplate: null,
    meta: [
      { name: 'description', content: 'The best online tranny cam models from around the world', id: 'desc' }
    ]
  },
  components: {
    ListActiveModels
  }
}
</script>

<style scoped lang="scss">
.site-logo {
  display: none;
}
</style>
